<template>
  <div id="fansList">
    <Spin size="large" fix v-show="spinShow"></Spin>
    <div class="no-results" v-show="total === 0 && !spinShow">
      <img :src="require('@/assets/qietu/article.png')" alt="">
      <p>暂无粉丝</p>
    </div>
    <Row :gutter="16" class="list">
      <Col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" :xxl="8" v-for="item in list" :key="item.userInfo.userId">
        <div class="listItem">
          <div class="top">
            <div class="lastName">
                <img
                  :src="$global.imgUrl + item.userInfo.headPhoto"
                  alt=""
                  class="headImg"
                  v-if="item.userInfo.headPhoto"
                />
                <img
                  class="headImg"
                  v-else
                  :src="require('@/assets/imgs/head_bg.png')"
                />
            </div>
            <div class="firstName">
              <span class="nameBox">{{item.userInfo.realname}}</span>
              <img
                style="width: 18px; height: 18px;"
                src="@/assets/svg/xiaoren.svg"
                alt=""
              />
            </div>
            <div class="right">
              <Button v-throttleClick="2000" v-show="item.fstatus == 0" class="blue acitve" @click="onfocus(item.userInfo.userId,true)">已关注</Button>
              <Button v-throttleClick="2000" v-show="item.fstatus == 1" class="blue" @click="onfocus(item.userInfo.userId)">关注</Button>
              <div class="none" @click="toMessages(item.userInfo.userId,item.userInfo.reviewStatus,item.userInfo.realname,item.userInfo.headPhoto)">私信</div>
            </div>
          </div>
          <div class="userList">
            <img v-for="i in item.honour" :key="i.hid" v-lazy="$global.imgUrl1 + i.content" alt="" />
          </div>
          <div class="bottom">
            <div><span>职称：</span><span>{{item.userInfo.unit}}</span></div>
            <div class="omit"><span>研究领域：</span><span v-for="v in item.subject" :key="v.id">{{v.levelTwoName}}</span></div>
            <div class="omit"><span>学者机构：</span><span v-for="t in item.title" :key="t.id">{{t.levelThreeName}}</span></div>
          </div>
        </div>
      </Col>
    </Row>
    <Paging
      :total="total"
      :curPage="page"
      :limit="16"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Paging from "@/components/paging/index.vue";
import { getFansList,focusScholarApi,unfocusScholarApi } from "../../../../../apis/user";

export default {
  components: {
    Paging,
  },
  name: "fansList",
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
      spinShow: false,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    toMessages(id,type,name,foto) {
      this.$router.push({name:'letterUser',params:{id,name,foto,type}})
    },
    async onfocus(id, status=false) {
      if (this.hasLogin) {
        const resp = status ?  await unfocusScholarApi({scholarId: id}) : await focusScholarApi({scholarId: id})
        if (resp.messageType === 'Success') {
          this.getList()
          // this.$Message.success(resp.resultDesc);
          this.$Message.success(status ? '取消关注成功' : '关注成功');
        } else {
          this.$Message.error(resp.resultDesc);
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    handlePager(i) {
      this.page = i;
      this.getList()
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 16,
      };
      this.spinShow = true
      const { data } = await getFansList(obj);
      this.total = Number(data.total);
      this.list = data.list;
      this.spinShow = false
    },
  },
};
</script>

<style lang="scss" scoped>
#fansList {
  position: relative;
  padding: 0 40px 50px;
  padding-bottom: 50px;
  min-height: 500px;

  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
  .list {
    .listItem {
      margin-bottom: 30px;
      padding: 25px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      .top {
        display: flex;
        align-items: center;
        position: relative;
        .lastName {
          width: 62px;
          height: 62px;
          text-align: center;
          color: #444444;
          line-height: 62px;
          background: #f6f6f6;
          border: 1px solid #f6f6f6;
          border-radius: 50%;
          overflow: hidden;
          .headImg {
            width: 62px;
            height: 62px;
          }
        }

        

        .firstName {
          margin-left: 10px;
          flex-grow: 1;
          .nameBox {
            display: inline-block;
            max-width: 130px;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 26px;
            color: #333333;
            vertical-align: middle;
          }
          img {
            margin-left: 5px;
            vertical-align: middle;
          }
        }

        .right {
          font-size: 14px;
          margin-left: 10px;

          .blue {
            cursor: pointer;
            width: 82px;
            height: 34px;
            color: #fff;
            border-radius: 10px;
            text-align: center;
            line-height: 34px;
            margin-bottom: 7px;
            background-color: #00a4ff;
            &.acitve {
              color: #00a4ff;
              border: 1px dotted #00a4ff;
              background-color: #fff;
            }
          }

          .none {
            margin-top: 7px;
            cursor: pointer;
            color: #999999;
            width: 82px;
            height: 34px;
            border: 1px dotted #999999;
            border-radius: 10px;
            //margin-left: 30px;
            text-align: center;
            line-height: 34px;
          }
        }
      }
      .userList {
        margin: 10px 0 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .bottom {
        div {
          line-height: 26px;
          font-weight: 400;
          color: #444444;
        }
        .omit {
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
