<template>
  <div id="certifiedList">
    <div class="title">以下为未认证粉丝用户，系统无法保证用户的真实性</div>
    <Row class="list">
      <Col :lg="12" :xl="8" :xxl="6" v-for="item in list" :key="item.userId">
        <div class="listBox">
          <div class="left">{{item.userName}}</div>
          <div class="right">
            <div>关注</div>
            <div>私信</div>
          </div>
        </div>
      </Col>
    </Row>
    <Paging
      :total="total"
      :curPage="page"
      :limit="8"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import Paging from "@/components/paging/index.vue";
// import { getFansNoList,focusScholarApi,unfocusScholarApi } from "../../../../../apis/user";
import { getFansNoList } from "../../../../../apis/user";
export default {
  name: "certifiedList",
  components: {
    Paging,
  },
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toMessages(obj,id,type,name,foto) {
      console.log(obj);
      console.log(id,type,name,foto);
      // this.$router.push({name:'letterUser',params:{id,name,foto,type}})
    },
    async onfocus() {
      this.$Message.warning("暂不能关注未认证用户");
      // const resp = status ?  await unfocusScholarApi({scholarId: id}) : await focusScholarApi({scholarId: id})
      // if (resp.messageType === 'Success') {
      //   this.getList()
      //   this.$Message.success(resp.resultDesc);
      // } else {
      //   this.$Message.error(resp.resultDesc);
      // }
    },
    handlePager(i) {
      this.page = i;
      this.getList()
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 16,
      };
      const {data} = await getFansNoList(obj)
      this.total = Number(data.total);
      this.list = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
#certifiedList {
  padding: 0 40px 20px;
  .title {
    margin-bottom: 20px;
    padding-top: 35px;
    border-top: 1px solid #e6e6e6;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #444444;
  }
  .list {
    .listBox {
      margin-bottom: 30px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        height: 83px;
        line-height: 83px;
      }
      .right {
        height: 83px;
        div {
          margin-bottom: 15px;
          width: 82px;
          text-align: center;
          line-height: 34px;
          height: 34px;
          border: 1px dotted #999999;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
