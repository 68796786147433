<template>
  <div id="fans">
    <TopList :navList="navList" @setType="setType" :type="type"> </TopList>
    <FansList></FansList>
    <CertifiedList></CertifiedList>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import FansList from "./components/fansList";
import CertifiedList from "./components/certifiedList";
export default {
  name: "index",
  components: {
    TopList,
    FansList,
    CertifiedList,
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
  },
  data() {
    return {
      type: 0,
      name: "",
      navList: ["粉丝列表"],
    };
  },
};
</script>

<style lang="scss" scoped>
#fans {
  // padding: 0 40px;
  min-height: 800px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
